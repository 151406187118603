import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";
const OurPrincipal = () => {

    return (
    <>
            <Header />
            <Breadcrumb mid="About" breadcrumbName="Principals" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1><span>Our  </span> Principals</h1>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal">
                                <div className="principal-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/principal-jacob-bona-02.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                </div>
                                <div className="our-principal-text">
                                    <h4>Rev. Fr. Jacob Bona D’ Souza</h4>
                                    <h5>July 2020 Onwards</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal">
                                <div className="principal-img">

                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/2014–2020.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                </div>
                                <div className="our-principal-text">
                                    <h4>Rev. Fr. Gregory D’souza</h4>
                                    <h5>July 2014 – July 2020</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal"><div className="principal-img">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/2007–2014.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                <div className="our-principal-text">
                                    <h4>Rev. Fr. William Mathias</h4>
                                    <h5>July 2007 – June 2014</h5>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal">
                                <div className="principal-img">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/2004–2007.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                    <div className="our-principal-text">
                                        <h4>Late Rev. Fr. Michael D´souza</h4>
                                        <h5>July 2004 — June 2007</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal"><div className="principal-img">

                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/2000–2004.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                <div className="our-principal-text">
                                    <h4>Late Rev. Fr. Jerome D´souza</h4>
                                    <h5>July 2000 — June 2004</h5>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal"><div className="principal-img">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/1992–2000.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                <div className="our-principal-text">
                                    <h4>Late Rev. Fr. Benedict Periera</h4>
                                    <h5>July 1992 — June 2000</h5>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <div className="our-principal"><div className="principal-img">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/1989-1992.jpg" alt="St. Mary School Mirzapur" /></LazyLoad>
                                <div className="our-principal-text">
                                    <h4>Rev. Fr. Sebastian D´cruz</h4>
                                    <h5>July 1989 — June 1992</h5>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    </div> 
                </div>
                <Footer />
            </>
            )
}

            export default OurPrincipal