import { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getGalleryInner } from "../Service/Api";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from 'react-lazyload';

const Gallery = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState("All");
  const [monthOptions, setMonthOptions] = useState(["All"]);
  const [yearRanges, setYearRanges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Helper to calculate session year
  const getSessionYear = (date = new Date()) => {
    const currentYear = date.getFullYear();
    const isPastJune = date.getMonth() >= 6; // July or later starts a new session
    const startYear = isPastJune ? currentYear : currentYear - 1;
    const endYear = isPastJune ? currentYear + 1 : currentYear;
    return `${startYear}-${endYear}`;
  };

  useEffect(() => {
    const currentSessionYear = getSessionYear();
    setSelectedYear(currentSessionYear);
    console.log("Current Session Year:", currentSessionYear);
  }, []);

  const convertDateToDDMMYYYY = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      const galleryData1 = await getGalleryInner();
      const galleryData = galleryData1.filter((item) => item.category === "PhotoGallery");

      // Convert date to DD MM YYYY format
      const formattedGalleryData = galleryData.map((item) => ({
        ...item,
        formattedDate: item.date ? convertDateToDDMMYYYY(item.date) : null,
      }));

      setData(formattedGalleryData);

      const yearsSet = new Set();

      formattedGalleryData.forEach((item) => {
        if (item.date) {
          const sessionYear = getSessionYear(new Date(item.date));
          yearsSet.add(sessionYear);
        }
      });

      const uniqueYearRanges = Array.from(yearsSet).sort((a, b) => b.localeCompare(a));
      setYearRanges(uniqueYearRanges);
    };

    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = data.filter((item) => {
      const itemDate = item.date?.trim();
      if (itemDate) {
        const sessionYear = getSessionYear(new Date(itemDate));
        return sessionYear === selectedYear;
      }
      return false;
    });

    // If no data for selectedYear, fallback to previous year
    if (filteredData.length === 0) {
      const selectedYearIndex = yearRanges.indexOf(selectedYear);
      if (selectedYearIndex < yearRanges.length - 1) {
        const previousYear = yearRanges[selectedYearIndex + 1];
        filteredData = data.filter((item) => {
          const itemDate = item.date?.trim();
          if (itemDate) {
            const sessionYear = getSessionYear(new Date(itemDate));
            return sessionYear === previousYear;
          }
          return false;
        });
      }
    }

    // Apply additional filters for month and search query
    if (selectedMonth !== "All") {
      filteredData = filteredData.filter((item) => {
        const itemDate = item.date?.trim();
        if (itemDate) {
          const month = new Date(itemDate).getMonth();
          return monthNames[month] === selectedMonth;
        }
        return false;
      });
    }

    if (searchQuery) {
      filteredData = filteredData.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Sort the filtered data by date in descending order
    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredData(filteredData);
  }, [selectedYear, selectedMonth, searchQuery, data, yearRanges]);

  const handleModal = (id) => {
    navigate(`/SubGallery?id=${id}`);
  };

  return (
    <>
      <Header />
      <Breadcrumb mid="Gallery" breadcrumbName="Photo Gallery" />

      <div className="innerSection">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title">
                <h1>
                  <span>Photo</span> Gallery
                </h1>
              </div>
            </div>
          </div>

          <div className="row tabs-dynamic">
          <div className="col-xxl-3 col-xl-3 col-lg-6 col-12">
                    <div className="count-val">
                      <p>Total Categories: {filterData.length}</p>
                    </div>
                  </div>
            {/* Year Selection */}
            <div className="col-lg-3">
              <select value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} className="yearSelection">
                {yearRanges.map((yr) => (
                  <option key={yr} value={yr}>
                    {yr}
                  </option>
                ))}
              </select>
            </div>

            {/* Month Selection */}
            <div className="col-lg-3">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="monthSelection"
              >
                {monthOptions.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {/* Search Box */}
            <div className="col-lg-3">
              <input
                type="text"
                placeholder="Search Here..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            {filterData.length > 0 ? (
              filterData.map((item, index) => (
                <div key={index} className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-12 galleryCount">
                <div className="gal_new">
                  <article>
                    <LazyLoad><img src={`https://webapi.entab.info/api/image/${item?.attachments[0]}`} alt="St mary school, Mirzapur" className="img-fluid" /></LazyLoad>
                    <div className="gal-content">
                      <h4 className="catgry-name">{item.title}</h4>
                      <p>{item.description}</p>
                      <h6 className="date">
      <i className="bi bi-calendar-week"></i> {item.formattedDate}
      <span>
        <a onClick={() => navigate(`/SubGallery?id=${item._id}`)}>
          <i className="bi bi-arrow-right"></i>
        </a>
      </span>
    </h6>
                      <div className="total-img"><i className="bi bi-card-image"></i><p>{item?.attachments.length}</p></div>
                    </div>
                  </article>
                </div>
              </div>
              ))
            ) : (
              <p>No data available for the selected year.</p>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
