import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";
const VoiceSMS = () => {

    return (
        <>
            <Header />
            <Breadcrumb mid="Gallery" breadcrumbName="Voice SMS" />
            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Voice SMS</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6"> 
                                <div className="audio-service">
                                    <div className="thumb">
                                        <LazyLoad><img className="img-fluid" src="https://webapi.entab.info/api/image/SMSMU/public/Images/audio.png" alt="St Mary school Mirzapur" /></LazyLoad>
                                    </div>
                                    <div className="details clearfix">
                                        <h3 className="sub_title"> Voice SMS : National Anthem </h3>
                                        <p>Voice of : 'Nation'</p>
                                        <p>Update On : '10 Aug 2022'</p>
                                        <audio controls>
  <source src="https://webapi.entab.info/api/image/SMSMU/public/Images/1.mp3" type="audio/ogg" /> 
  <source src="https://webapi.entab.info/api/image/SMSMU/public/Images/1.mp3" type="audio/mpeg" />
  Your browser does not support the audio tag.
</audio>
                                    </div>
                                    {/* <audio src="https://webapi.entab.info/api/image/SMSMU/public/Images/1.mp3" controls="" preload=""> </audio> */}
                                   
                                </div> 
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default VoiceSMS