import React, { useState , useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Highlights from './Highlights';
import LazyLoad from 'react-lazyload';

const Header = () => {
    
    const marqueeRef = useRef(null);

    const handleMouseOver = () => {
      marqueeRef.current.stop();
    };
  
    const handleMouseOut = () => {
      marqueeRef.current.start();
    };
    // const MarqueeComponent = ({ children }) => {
       
      
    //     const handleMouseOver = () => {
    //       setIsHovered(true);
    //     };
      
    //     const handleMouseOut = () => {
    //       setIsHovered(false);
    //     };
      
      
    //   };

    const [isOpen, setIsOpen] = useState(window.innerWidth >= 991);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth >= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        
        <div className="header ">
            <div className="">
                <div className="container-fluid pd-0">
                    <div className="row">
                        <div className='col-md-2 highlight-tab'>
                           
                        </div>
                        <div className='col-md-8'>
                        
 

                        </div>
                        <div className='col-md-2 highlight-tab'>
                            <p className="d-flex space-even highlight-blk">
                                <a href="https://twitter.com/stthomasschool_?lang=en" target="_blank">
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/SMSMU/public/Images/twitter.png'></img></LazyLoad>
                                    </a>
                                <a href="https://www.instagram.com/st.thomasschool.mainpuri/" target="_blank">
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/SMSMU/public/Images/insta.png'></img></LazyLoad>
                                    </a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank">
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/SMSMU/public/Images/facebook.png'></img></LazyLoad>
                                    </a>
                                <a href="https://www.youtube.com/watch?v=6-sKinVruzI" target="_blank">
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/SMSMU/public/Images/linkdin.png'></img></LazyLoad>
                                    </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-blk">
                <div className='container-fluid'>
                    <div className='row'>
                    <div className='col-lg-4 col-md-4'>
                            <div className='logo'>
                                <Link to="/">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Logo.png" className='img-fluid'></img></LazyLoad>
                                </Link>
                            </div>
                        </div>  
                    <div className='col-lg-4 col-md-4'>
                            <div className="contact-tab"> 
                                <div><p className="border-tab">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/phone.gif" alt="St mary school mirzapur" ></img> </LazyLoad>
                                    Call us <div><a href="tel: 05442-350050"> <span>05442 350050</span></a>,<a href="tel: 05442-350051"> <span>51</span></a>,<a href="tel: 05442-350052"> <span>52</span></a>  </div>
                              </p></div> 
                                <div><p className="border-tab">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/email.gif" alt="St mary school mirzapur" ></img></LazyLoad>
                                     Email us<div><a href="mailto: stmarysujn@rediffmail.com"> stmarymzp1@rediffmail.com</a></div></p></div> 
                            </div>
                        </div>
                                             
                        <div className='col-lg-4 col-md-4'>
                            <div className="header-contact"> 
                            
                                <div ><a href="https://smm.campuscare.info/"><p className="border-tab">Online Fee<br></br> Payment</p></a></div>
                                <div><Link to="/magazine"><p className="border-tab">School<br />Magazine</p></Link></div>  
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            <div className="menu">
                <button className="menu-toggle" onClick={toggleMenu}>
                <i className="bi bi-list"></i>
                </button>
                {isOpen && (
                    <ul className="submenu  animate__animated animate__fadeIn animate__delay-1s">
                        <li><Link to='/'>Home</Link></li>
                        <li>School
                             <ul className="sub-submenu">
                                <li><Link to='/about'>About us</Link></li> 
                                <li><Link to='/history-aim'>History, Aim, Vision &amp; Mission</Link></li> 
                                <li><Link to="/PrincipalMessage">Principal's Desk</Link></li> 
                                <li><Link to="/ourPrincipal">Our Principals</Link></li>
                                <li><Link to="/comingSoon">Community Program</Link></li>
                                <li><Link to="/RulesRegulations">Rules &amp; Regulations</Link></li>
                                <li><Link to="/admissionProcedure">Admission Procedure</Link></li>
                                <li><Link to="/schoolUniform">School Uniform</Link></li>
                                <li><Link to="/curriculum">Curriculum</Link></li>
                                <li><Link to="/RoleOfHonour">Role Of Honour</Link></li>
                                <li><Link to="/comingSoon">House Points</Link></li>
                            </ul> 
                        </li>

                        <li>
                        Academics
                           <ul className="sub-submenu">
                                <li><Link to="/management-committee">Management Committee</Link></li> 
                                <li><Link to="/faculty">Faculty &amp; Staff</Link></li> 
                                <li><Link to="/formerFaculty">Former Faculty</Link></li> 
                                <li><Link to="/syllabus">Syllabus</Link></li> 
                                <li><Link to="/comingSoon">Student Leaders</Link></li> 
                                <li><Link to="/comingSoon">Streams & Subjects</Link></li> 
                            </ul>  
                        </li> 

                        <li>
                        Facilities 
                            {/* Admission <i className="bi bi-chevron-down"></i> */}
                             <ul className="sub-submenu">
                                <li><Link to="/campusTour">Camps &amp; Tours</Link></li>
                                <li><Link to="/Laboratory">Laboratories</Link></li>
                                <li><Link to="/library">Library</Link></li> 
                                <li><Link to="/sports">Sports</Link></li> 
                                <li><Link to="/comingSoon">Summer Camp</Link></li> 
                            </ul>  
                        </li> 
                        <li>
                        Results
                              <ul className="sub-submenu">
                                <li><Link to="/school-topper">School Toppers </Link></li> 
                                <li><Link to="/classTopper">Class Toppers</Link></li>  
                            </ul> 
                        </li>
                        <li>
                        Gallery  
                              <ul className="sub-submenu">
                               
                                <li><Link to="/Gallery">Photo Gallery</Link></li>
                                <li><Link to="/VideoGallery">Video Gallery</Link></li>
                                <li><Link to="/voiceSMS">Voice SMS</Link></li>
                                <li><Link to="/magazine">Magazines</Link></li>
                            </ul>  
                        </li> 
                        <li>
                            <Link to="/alumni">
                        Alumni</Link>
                            {/* <ul className="sub-submenu">
                                <li><Link to="/gallery">Photo Gallery</Link></li>
                                <li><Link to="/VideoGallery">Video Gallery</Link></li>
                            </ul> */}
                        </li> 
                        <li><Link to="/contactUs">Contact</Link></li>
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Header;