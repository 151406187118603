import Header from "../components/Header"
import Footer from "../components/Footer"
import Breadcrumb from "../components/Breadcrumb"
import LazyLoad from "react-lazyload"
const Sports = () => {
    return (
        <>
            <Header />
            <Breadcrumb mid="Facilities" breadcrumbName="Sports" />

            <div className="innerSection">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title">
                                <div>
                                    <h1>Sports</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>Sports and games is encouraged in our school. Games like Cricket, Football, Throw ball, Basketball ball, Volleyball and Badminton are played by the students. We have 3 games teachers, two male and one female to train the students in the various disciplines. We also have the Annual Athletic and Sports Day each year. The competitions are held house wise.</p>
                        </div>
                    </div>
                    <div class="row">
                   
                       

                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Park1.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Children's Park</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Park2.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Children's Park</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Park3.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Children's Park</h3>
                            </div>
                        </div>



                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/PlayGround.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Play Ground</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/SportsRoom-1.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Sports Room</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/SportsRoom-2.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Sports Room</h3>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                            <div class="labs-box">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/basket-ball.jpg" alt="St Mary School, Mirzapur" className="img-fluid" /></LazyLoad>
                                <h3>Basket Ball</h3>
                            </div>
                        </div>







                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Sports