// import $ from 'jquery'
import Header from '../components/Header'
import News from '../components/News'
import Copyright from '../components/Copyright'
import SliderComponent from '../components/lightslider'
import Events from '../components/Events'
import PrincipalMessage from '../components/PrincipalMessage'
import Birthday from '../components/Birthday'
import Topper from '../components/Topper'
import Footer from '../components/Footer'
import Notification from '../components/Notification'
import { Link, Navigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Highlights from '../components/Highlights' 
import Facilities from '../components/Facilities';
import Gallery from '../components/Gallery'
import OnlineReg from '../components/OnlineReg'
import LazyLoad from 'react-lazyload'

//import WOW from 'wow.js'; // Import WOW.js library
const Home = () => {
    
    var settings = {
        loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        items: 1,
        responsive: {
            0: {
                items: 1,
            },
            567: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },
            1600: {
                items: 1,
            }
        }
    };



    return (
        <>
  <OnlineReg/>
            <Header></Header>
            <div className='notification'>
                <Notification />
            </div>
            {/* <div className='online-reg'>
                    <img src="https://webapi.entab.info/api/image/SMSMU/public/Images/registration.png" />
                </div> */}
              
            <div className='container-fluid min-ht'>
                <div className="row">

                    <div className='col-md-12 pd-0 order-lg-12'>
                        <SliderComponent />
                    </div>
                </div>

            </div>
            <div class="bg-image">
            <div className='vision-mission'>
                <div class="container">
                    <div className='row'>
                        <div className='col-md-3 col-sm-6' data-animation="fadeInLeft">
                            <div className='mission'>
                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSMU/public/Images/mission.gif' alt="St mary school mirzapur" ></img></LazyLoad> Mission</h3>
                                <p>St.Mary’s School, Mirzapur, aims at creating individuals who are intellectually competent, morally sound, psychologically whole, imbued with the sense of the divine, committed to the cause of justice, love and peace, and ever open to further growth. It aspires towards creating a humane society free from prejudices, superstitions and discrimination based on sex, religion, caste creed and economic status. It strives to inculcate respect for the dignity of the human person and concern for each other, especially the underprivileged in the society. To make this mission a reality it expects parents, guardians, teachers and well-wishers to share this mission in spirit with the school management and co-operate wholeheartedly in all its endeavors.</p>
                                
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6 vision-mission-col animateMe' data-animation="fadeInRight">
                            <div className='vission'>
                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSMU/public/Images/vision.gif' alt="St mary school mirzapur" ></img></LazyLoad> Vision</h3>
                                <p>St. Mary´s School stands for academic excellence, development of skills and character formation based on the love of God and the service of man as modeled in Jesus Christ. It exist primarily for the education of Catholic children but extends its service to the development of others irrespective of caste, creed and religion. English is the medium of instruction, communication and examination. The school is affiliated to the C.I.S.C.E. , New Delhi, India</p>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6 vision-mission-col animateMe' data-animation="fadeInRight">
                            <div className='motto'>
                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSMU/public/Images/motto.gif' alt="St mary school mirzapur" ></img></LazyLoad> Aim </h3>
                                <p> The Aim of S.M.S<br></br>Every child is a gift of God. Our commitment is to have integral education, the harmonious loving of its society. To appreciate the world and to live in it as a global citizen.</p>
                            </div>
                        </div>
                        <div className='col-md-3 col-sm-6 vision-mission-col animateMe' data-animation="fadeInRight">
                            <div className='highlight'>

                                <h3><LazyLoad> <img src='https://webapi.entab.info/api/image/SMSMU/public/Images/announcement.gif' alt="St mary school mirzapur" ></img></LazyLoad> Announcement</h3>
                                <Highlights />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-us">
                <div className='container'>
                    <div className='row'>
                        
                        <div className='col-lg-6 col-md-12 animateMe' data-animation="fadeInLeft">
                            <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/about-img.png" className='img-fluid' alt="St mary school mirzapur" /></LazyLoad>
                        </div>
                        <div className='col-lg-6 col-md-12 animateMe' data-animation="fadeInRight">
                         <div className="title">
                                <div>
                                    <p>ABOUT US</p>
                                    <h3>St. Mary's School & College Mirzapur, Uttar Pradesh</h3>
                                </div> 
                            </div>
                            <p><span>St. Mary′s School</span> was born on 10th July 1989, is a minority institution and administered by the Roman Catholic Diocese of Allahabad.The school is dedicated to our blessed Mother Mary, Mother of Lord Jesus Christ. Fr. Sebastian D′cruz began the construction and started the school with 4 classrooms with just 175 students and 6 teachers. Fr. Dennis D′souza was the Diocesan Education Officer in those days.</p>
                            <p><span>St. Mary′s School, Mirzapur,</span> aims at creating individuals who are intellectually competent, morally sound, psychologically whole, imbued with the sense of the divine, committed to the case of justice, love and peace, and ever open to further growth.</p>
                            <div className='button abt-button'><Link to="/about">
                                <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/Read-more.png" alt="St mary school mirzapur" /></LazyLoad>
                                </Link></div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className='news-event'>
                <div>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-4 news-bg animateMe' data-animation="fadeInLeft">
                            <div className="title">
                                <div>
                                    <p>whats happening</p>
                                    <h3>latest news &<br></br> Notices</h3>
                                </div>
                                <div className='button'><Link to="/News">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/more-icon.png" alt="St mary school mirzapur" className='ml-0'></img></LazyLoad>
                                    </Link></div>
                            </div>
                            <div className='news'>
                                
                                <div className='news-blk'>
                                    <News /> 
                                    <LazyLoad><img src='https://webapi.entab.info/api/image/SMSMU/public/Images/news-img.png' alt="St mary school mirzapur" /></LazyLoad>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 animateMe' data-animation="fadeInRight">
                        <div className="title">
                                <div>
                                    <p>Events & Updates</p>
                                    <h3>Upcoming<br></br> Events</h3>
                                </div>
                                <div className='button'><Link to="/Events">
                                    <LazyLoad><img src="https://webapi.entab.info/api/image/SMSMU/public/Images/more-icon.png" className='ml-0' alt="St mary school mirzapur"></img></LazyLoad>
                                    </Link></div>
                            </div>
                            <div className='events'>
                                 
                                <div className='events-blk'>
                                    <Events /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='facilities-main'>
            <div className="facilities">
                <div className="title center">
                    <div>
                        <p>facilities in the school</p>
                        <h3>Our Facilities</h3>
                    </div> 
                </div>
                <Facilities />
            </div>
            </div>
          
         
         
            <div className='birth-topper'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 animateMe" data-animation="fadeInLeft">
                            <div className='birthday'>
                            <div className="title center">
                    <div>
                        <p>Stars of the day</p>
                        <h3>Birthday section</h3>
                    </div> 
                </div>
                               
                            </div>
                        </div>
                        <Birthday />
                        {/* <div className="col-lg-8 col-md-8 animateMe" data-animation="fadeInRight">
                            <div className='topper'>
                                <div className="title">
                                    <div class="title-gif">
                                        <img src='https://webapi.entab.info/api/image/SMSMU/public/Images/trophy.gif'></img>
                                    </div>
                                    <h3>SCHOOL TOPPERS</h3>
                                </div>
                                <Topper />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="message">
                <div className='container'>
                    <div class="row">
                        <div class="col-md-12">
                        <div className="title center">
                    <div>
                        <p>from the</p>
                        <h3>Message Desk</h3>
                    </div> 
                </div>
                            <OwlCarousel className='owl-theme message-blk' loop margin={10} {...settings}>
                                <PrincipalMessage />
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            <div className='gallery'>
                
                 <Gallery /> 
             
            </div>
            
            <Footer /> 
        </>
    )
}
export default Home;