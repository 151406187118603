import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import { Link } from 'react-router-dom'
import Header from "../components/Header";
import Footer from "../components/Footer"; 
import Breadcrumb from "../components/Breadcrumb";
import LazyLoad from "react-lazyload";
const SubGallery = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, [id]); // Add id as a dependency


  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://webapi.entab.info/api/image/${img}`
  }));
  return (
    <>
      <Header />
      <Breadcrumb mid="Gallery" breadcrumbName="Photo Gallery" />

      <div className="innerSection">
        <div className="container">
          <div className="row tabs-dynamic">
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div className="shrink"><Link to="/Gallery"><i className="bi bi-arrow-left"></i></Link></div>


            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div><b>Event Date:</b> {" "}
                {new Date(data.date).toLocaleDateString("en-GB")}</div>


            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-4 col-12">
              <div className="month-selection">
                <div><b>Updated On:</b>  {new Date(data.date).toLocaleDateString("en-GB")}</div>

              </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-3 col-12">
              <div className="month-selection">
                <div><b>Total Photos:</b> {data?.attachments?.length}</div>
              </div>
            </div>

          </div>
          <div className="row">
            <div className="col-md-12">



              <div className="fulwidth">

                <div className="discription">
                  <h1 className="subhead">{data.title}</h1>
                  <p>{data.description}</p>
                </div>
              </div>
              <div className="sub_gallery row">
                {data && data.attachments && data.attachments.length > 0 && (
                  data.attachments.map((img, index) => (
                    <div className="col-lg-3 col-md-64 col-sm-12" key={index}>
                      <a data-magnify="gallery" data-src="" data-group="a" href={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)}>
                        <LazyLoad><img src={`https://webapi.entab.info/api/image/${img}`} onClick={() => setIndex(index)} className="img-fluid" alt={`Image ${index}`} /></LazyLoad>
                      </a>
                    </div>
                  ))
                )}

          
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer /> 
    </>
  )
}

export default SubGallery